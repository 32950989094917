<template>
  <svg
    width="155"
    height="90"
    viewBox="0 0 155 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M115.715 51.0426V38.9569H85.7671L87.301 33.0427C90.901 19.4142 97.9723 12.8571 110.829 12.8571C113.658 12.8571 116.744 13.3714 120.215 14.5285L126.644 3.21427C122.015 1.28571 116.229 0 111.087 0C91.801 0 79.201 9.51423 74.0582 29.6998L71.6565 38.9569H38.5727V51.0426H68.5207L66.9868 56.9568C63.3868 70.5853 56.1869 77.1424 43.4584 77.1424C40.5013 77.1424 37.4156 76.6281 34.0727 75.471L27.5156 86.7852C32.2727 88.7138 38.0584 89.9995 43.2012 89.9995C62.4868 89.9995 74.8296 80.4853 80.2296 60.2997L82.6313 51.0426H115.715Z"
      fill="#474AEE"></path>
    <path
      d="M12.8571 44.9997C12.8571 27.257 27.257 12.8571 44.9997 12.8571C48.7283 12.8571 52.3283 13.4999 55.7997 14.7856L62.3568 3.47141C56.8283 1.15714 50.914 0 44.9997 0C20.1856 0 0 20.1856 0 44.9997C0 59.0139 6.68568 72.2567 17.6142 80.7424L24.1713 69.4282C17.0999 63.3853 12.8571 54.514 12.8571 44.9997Z"
      fill="#474AEE"></path>
    <path
      d="M136.544 9.25757L130.115 20.5718C137.187 26.6146 141.429 35.486 141.429 45.0002C141.429 62.743 127.029 77.1429 109.287 77.1429C105.558 77.1429 101.958 76.5 98.4868 75.2143L91.9297 86.5285C97.4582 88.8428 103.372 89.9999 109.287 89.9999C134.101 89.9999 154.286 69.8144 154.286 45.0002C154.286 30.986 147.601 17.7432 136.544 9.25757Z"
      fill="#474AEE"></path>
  </svg>
</template>
